/** @jsx jsx **/
import { jsx } from 'theme-ui'
import React from 'react'

const Stack = props => (
    <div sx={{
      display: 'flex',
      flexDirection: 'column',
      gap: props.gap,
    }}>
      {props.children}
    </div>
  )
  export default Stack